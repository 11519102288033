@font-face {
  font-family: 'Inter';
  src: url('../src/assets/fonts/Inter/static/Inter-Regular.ttf');
}
@font-face {
  font-family: 'AbhayaLibre';
  src: url('../src/assets/fonts/Abhaya_Libre/AbhayaLibre-Regular.ttf');
}
@font-face {
  font-family: 'LibreBaskerville';
  src: url('../src/assets/fonts/Libre_Baskerville/LibreBaskerville-Regular.ttf');
}

body {
  background: #F5F5FF;
  width: 100%;
  margin: auto;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Inter';
  box-sizing: border-box;
  border: none;
  outline: none;
}
.LibreBaskerville{
  font-family: 'LibreBaskerville';
}

button {
  cursor: pointer;
  border: none;
  background: transparent;
}
.text_capitalize{
  text-transform: capitalize;
}

.container {
  max-width: 1848px;
  width: 100%;
  margin: 0 auto;
}
.container_dashboard {
  max-width: 1429px;
  width: 100%;
  margin: 0 auto;
}

.container_inner {
  padding: 0px 16px;
}

.d_grid {
  display: grid;
  align-items: center;
  justify-items: center;
}

.grid_columns_4fr {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.grid_columns_3fr {
  grid-template-columns: 1fr 1fr 1fr;
}

.grid_columns_2fr {
  grid-template-columns: 1fr 1fr;
}

.grid_gab_35 {
  grid-gap: 35px;
}

.grid_gab_50 {
  grid-gap: 50px;
}

.d_flex {
  display: flex;
}

.fd_row {
  flex-direction: row;
}

.fd_column {
  flex-direction: column;
}

.justify_content_center {
  justify-content: center;
}

.justify_content_end {
  justify-content: flex-end;
}

.justify_content_start {
  justify-content: start;
}

.justify_content_space_between {
  justify-content: space-between;
}

.align_items_center {
  align-items: center;
}

.align_items_start {
  align-items: flex-start;
}

.align_items_end {
  align-items: flex-end;
}

.f_400 {
  font-weight: 400;
}

.f_500 {
  font-weight: 500;
}

.f_600 {
  font-weight: 600;
}

.f_700 {
  font-weight: 700;
}

.fs_10 {
  font-size: 10px;
  line-height: 16px;
}

.fs_12 {
  font-size: 12px;
  line-height: 18px;
}

.fs_14 {
  font-size: 14px;
  line-height: 18px;
}

.fs_16 {
  font-size: 16px;
  line-height: 24px;
}

.fs_18 {
  font-size: 18px;
  line-height: 27px;
}
.fs_20{
  font-size: 20px;
  line-height: 28px;
}
.fs_24 {
  font-size: 24px;
  line-height: 36px;
}
.fs_28 {
  font-size: 28px;
  line-height: 36px;
}
.fs_32 {
  font-size: 32px;
  line-height: 39px;
}
.fs_36 {
  font-size: 36px;
  line-height: 44px;
}
.fs_38 {
  font-size: 38px;
  line-height: 46px;
}
.fs_48{
  font-size: 48px;
  line-height: 58px;
}


.fs_60 {
  font-size: 60px;
  line-height: 73px;
}

.c_white {
  color: #FFFFFF;
}

.c_black {
  color: #3C3C3C;
}
.c_blue{
  color: #191970;
}
.c_lightGrey{
  color: #C8C8C8;
}
.c_grey {
  color: #646464;
}

.c_red {
  color: red;
}
.c_green {
 color: #49D69C;
}

.bc_white {
  background: white;
}
.margin_top_16{
  margin-top: 16px;
}
.margin_top_48{
  margin-top: 48px;
}
.margin_bottom_96{
  margin-bottom: 96px;
}

.bc_blue {
  background: #191970;
}
.bc_darkBlue {
  background: #000034;
}
.bc_green {
  background: #49D69C;
}
.bc_purple {
  background: #B9B9FF;
}


input {
  background: none;
}

input:focus {
  outline: none;
}

a {
  text-decoration: none;
}

.text_center {
  text-align: center;
}

.text_left {
  text-align: left;
}

.main {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.error{
  color: red;
  font-size: 16px;
  margin-bottom: 8px;
  text-align: center;
}
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #787878;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #424242;
}

/*.guZdik {*/
/*  border-top: none !important;*/
/*  border-left: 1px dashed midnightblue !important;*/
/*  border-right: 1px dashed midnightblue !important;*/
/*  border-bottom: 1px dashed midnightblue !important;*/
/*  height: 180px !important;*/
/*  display: flex !important;*/
/*  flex-direction: column !important;*/
/*}*/
/*.jWkLDY > span {*/
/*  display: none !important;*/
/*}*/

/*.guZdik svg {*/
/*  display: none !important;*/
/*}*/

/*.guZdik:after {*/
/*  content: url("assets/images/folder-add.png") !important;*/
/*  position: absolute !important;*/
/*  top: 20% !important;*/
/*}*/

/*.guZdik:after {*/
/*  content: url("assets/images/folder-add.png") !important;*/
/*  position: absolute !important;*/
/*  top: 25% !important;*/
/*}*/

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
