.filters_b{
    margin-top: 56px;
}
.filters_b_item{
    max-width: 213px;
    width: 100%;
}
.filters_b_item:first-child{
  margin-right: 24px;
}
.filters_b_item label{
    margin-bottom: 8px;
}
.filters_b_item select{
    border: 0.5px solid #B4B4B4;
    border-radius: 4px;
    height: 44px;
    padding: 13px;
    width: 100%;
    color: #646464;
    background: transparent;
}