.sidebar {
    width: 411px;
    min-height: 100vh;
    height: 100%;
    background: white;
    padding: 32px 32px 8px 32px;
    z-index: 999;
    border-right: 1px solid #B4B4B4;
    border-bottom: 1px solid #B4B4B4;
}

.navLinks_block {
    height: 100%;
}

.sidebar_navLink, .sidebar_navLink_settings_b {
    padding: 8px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
}

.sidebar_navLink:hover {
    transform: scale(1.02);
    color: midnightblue;
}

.sidebar_navLink_active {
    padding: 8px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
}

.sidebar_navLink_active:hover {
    transform: scale(1.02);
}

.sidebar_navLink:first-child:before {
    display: flex;
    align-items: center;
    content: url("../../assets/images/dashboard_icon_black.svg");
    width: 24px;
    height: 24px;
    margin-right: 16px;
}

.sidebar_navLink_active:first-child:before {
    display: flex;
    align-items: center;
    content: url("../../assets/images/dashboard_icon_active.svg");
    width: 24px;
    height: 24px;
    margin-right: 16px;
}

.sidebar_navLink:nth-child(2):before {
    display: flex;
    align-items: center;
    content: url("../../assets/images/messages_icon.svg");
    width: 24px;
    height: 24px;
    margin-right: 16px;
}

.sidebar_navLink_active:nth-child(2):before {
    display: flex;
    align-items: center;
    content: url("../../assets/images/messages_icon_active.svg");
    width: 24px;
    height: 24px;
    margin-right: 16px;
}

.sidebar_navLink:nth-child(3):before {
    display: flex;
    align-items: center;
    content: url("../../assets/images/tasks_icon_black.svg");
    width: 24px;
    height: 24px;
    margin-right: 16px;
}

.sidebar_navLink_active:nth-child(3):before {
    display: flex;
    align-items: center;
    content: url("../../assets/images/tasks_icon_blue.svg");
    width: 24px;
    height: 24px;
    margin-right: 16px;
}

.sidebar_navLink:nth-child(4):before {
    display: flex;
    align-items: center;
    content: url("../../assets/images/favourities_icon.svg");
    width: 24px;
    height: 24px;
    margin-right: 16px;
}

.sidebar_navLink_active:nth-child(4):before {
    display: flex;
    align-items: center;
    content: url("../../assets/images/messages_icon_active.svg");
    width: 24px;
    height: 24px;
    margin-right: 16px;
}

.sidebar_navLink:nth-child(5):before {
    display: flex;
    align-items: center;
    content: url("../../assets/images/paymenr_history_icon.png");
    width: 24px;
    height: 24px;
    margin-right: 16px;
}

.sidebar_navLink_active:nth-child(5):before {
    display: flex;
    align-items: center;
    content: url("../../assets/images/messages_icon_active.svg");
    width: 24px;
    height: 24px;
    margin-right: 16px;
}

.sidebar_navLink:nth-child(6):before {
    display: flex;
    align-items: center;
    content: url("../../assets/images/payments_method_icon.svg");
    width: 24px;
    height: 24px;
    margin-right: 16px;
}

.sidebar_navLink_active:nth-child(6):before {
    display: flex;
    align-items: center;
    content: url("../../assets/images/messages_icon_active.svg");
    width: 24px;
    height: 24px;
    margin-right: 16px;
}

.sidebar_navLink:nth-child(7):before {
    display: flex;
    align-items: center;
    content: url("../../assets/images/refer_friend_icon_black.svg");
    width: 24px;
    height: 24px;
    margin-right: 16px;
}

.sidebar_navLink_active:nth-child(7):before {
    display: flex;
    align-items: center;
    content: url("../../assets/images/messages_icon_active.svg");
    width: 24px;
    height: 24px;
    margin-right: 16px;
}

/*.sidebar_navLink:nth-child(8):before {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    content: url("../../assets/images/settings_icon_black.svg");*/
/*    width: 24px;*/
/*    height: 24px;*/
/*    margin-right: 16px;*/
/*}*/

/*.sidebar_navLink_active:nth-child(8):before {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    content: url("../../assets/images/messages_icon_active.svg");*/
/*    width: 24px;*/
/*    height: 24px;*/
/*    margin-right: 16px;*/
/*}*/

.navLink_b2 .sidebar_navLink:nth-child(1):before {
    display: flex;
    align-items: center;
    content: url("../../assets/images/help_icon_black.svg") !important;
    width: 24px;
    height: 24px;
    margin-right: 16px;
}

.navLink_b2 .sidebar_navLink_active:nth-child(1):before {
    display: flex;
    align-items: center;
    content: url("../../assets/images/help_icon_black.svg") !important;
    width: 24px;
    height: 24px;
    margin-right: 16px;
}

.navLink_b2 .sidebar_navLink:nth-child(2):before {
    display: flex;
    align-items: center;
    content: url("../../assets/images/logout_icon.svg") !important;
    width: 24px;
    height: 24px;
    margin-right: 16px;
}

.sidebar_navLink_active {
    padding: 8px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
}

.navLink_b2 {
    margin-top: 34%;
}
.sidebar_navLink_settings {
    cursor: pointer;
}
.sidebar_navLink_settings .sidebar_navLink{
margin-bottom: 0px;
}
.sidebar_navLink_settings:before{
    content: url("../../assets/images/settings_icon_black.svg");
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.arrow_right_icon_black{
    cursor: pointer;
}



