.browse_skill_cont {
    padding: 143px 157px 103px;
    margin-bottom: 192px;
}

.browse_skill_cont_items {
grid-gap: 90px;
}

.browse_skill_cont_item img {
    width: 60px;
    height: 60px;
}

.browse_skill_cont_item h3 {
    margin-top: 24px;
    margin-bottom: 24px;
}

.browse_skill_cont_item span {
    margin-top: 16px;
}

.browse_skill_cont_item button {
    border: 1px solid #191970;
    border-radius: 10px;
    padding: 10px 12px;
}
.browse_skill_cont_item button:hover {
  background: midnightblue;
    color: white;
    font-weight: 700;
}