.upload_img_block {
    margin-bottom: 30px;
}

.upload_img_img_b {
    border-top: 1px dashed #191970;
    border-radius: 5px;
    max-width: 417px;
    width: 100%;
    margin: 0px auto 32px;
}

.upload_img_img_b .file_upload_title {
    padding: 16px;
    text-align: left;
    border-bottom: 1px solid #B4B4B4;
    border-left: 1px dashed midnightblue;
    border-right: 1px dashed midnightblue;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

/*.upload_img_img_b .upload_b{*/
/*    border-top: 1px solid #B4B4B4;*/
/*}*/
.upload_img_img_b .upload_b{
    /*padding: 38px 100px;*/
}
.upload_b p{
    margin: 8px auto 0px;
}
.folder_add_icon{
    cursor: pointer;
    width: 43px;
    height: 43px;
}