.img_b{
    max-width: 80px;
    width: 100%;
    height: 80px;
    margin: auto;
}
.img_b img{
    width: 100%;
}
.upload-list{
    max-width: 248px;
    width: 100%;
    margin-left: 27px;
}
.upload_ok_icon{
    margin-left: 24px;
    color: #3C3C3C;
    cursor: pointer;
}
.file-name_p{
    margin-bottom: 8px !important;
    color: #3C3C3C !important;
}
.rc-progress-line{
    height:10px !important;
    width: 100% !important;
}
.react_fragment_div{
    width: 417px;
}
.edit_icon{
    margin-right: 30px;
}