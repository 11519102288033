.subscribe_cont_block {
    margin-bottom: 180px;
}

.subscribe_cont_block img {
    max-width: 574px;
    width: 100%;
    margin: auto;
}
.subscribe_cont_block img:hover {
    transform: scale(1.05);
}


.subscribe_cont_block_desc {
    max-width: 666px;
    width: 100%;
    margin: 24px auto 0;
}

.subscribe_cont_block_input_b {
    max-width: 1020px;
    width: 100%;
    margin-bottom: 16px;
}

.subscribe_cont_block_input_b input {
    border: 1px solid #191970;
    border-radius: 5.30387px 0px 0px 5.30387px;
    width: 100%;
    padding: 31.5px 16px;
}

.subscribe_cont_block_input_b input::placeholder {
    font-size: 18px;
}

.subscribe_cont_block_input_b .subscribe_btn {
    width: 156px;
    padding: 24px 27.5px;
    border-radius: 0px 5.30387px 5.30387px 0px;
}
.subscribe_cont_block_input_b .subscribe_btn:hover {
    background: rgb(137, 137, 215);
    color: white;
}