.core_values_cont {
    margin-top: 169px;
}

.core_values_cont_blocks {
    grid-gap: 63px;
    margin-top: 74px;
}

.core_values_cont_block {
    padding: 24px 24px 74px;
}
.core_values_cont_block:hover {
   border: 1px solid midnightblue;
}
.core_values_cont_block img{
    max-width: 200px;
    width: 100%;
}
.core_values_cont_block h3{
    margin: 24px auto;
}