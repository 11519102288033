.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 350px;
    width: 100%;
    list-style-type: none !important;
    cursor: pointer;
    margin-top: 84px;
}

.pagination li  a{
    width: 40px;
    height: 40px;
    font-size: 18px;
    font-weight: 700;
    border-radius: 50%;
    background: #F5F5FF;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pagination li a.active {
    background: transparent;
}
.pagination li a:hover {
    background: #B9B9FF;
}
.pagination .previous a,  .pagination .next  a {
    background:white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    color: black;
    border: 1px solid grey;
}