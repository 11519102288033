.people_choose_container{
}
.people_choose_cont{
    margin-top: 48px;
}
.people_choose_pic_block{
    margin-right: 20px;
}
.people_choose_pic{
    width: 100%;

}
.people_choose_cont_block2{
   margin: auto;
}
.people_choose_cont_block2_item_pic {
    max-width: 100px;
    width: 100%;
    margin-right: 24px;
}

.people_choose_cont_block2_item {
    padding: 24px;
    max-width: 570px;
    width: 100%;
    background: white;
    box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    margin: 16px 16px 32px;
    height: 160px;
}
.people_choose_cont_block2_item:hover{
    transform: scale(1.03);
}