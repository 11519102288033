.rating_reviews_cont {
    padding: 48px;
    height: 775px;
    font-family: 'AbhayaLibre'  !important;
}
.rating_reviews_cont h2{
    margin-bottom: 140px;
}

.rating_reviews_cont_items {
    font-family: 'AbhayaLibre'  !important;
    position: relative;
    max-width: 1105px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.rating_reviews_cont_item1 {
    font-family: 'AbhayaLibre'  !important;
    position: absolute;
    top: -20%;
    right: 50%;
    transform: rotate(-35deg);
    padding: 32px;
    max-width: 454px;
    width: 100%;
    border-radius: 10px;
}
.rating_reviews_cont_item1 p, .rating_reviews_cont_item2 p, .rating_reviews_cont_item3 p {
    font-family: 'AbhayaLibre'  !important;
}
.rating_reviews_cont_item1 span, .rating_reviews_cont_item2 span, .rating_reviews_cont_item3 span {
    font-family: 'AbhayaLibre'  !important;
}

.rating_reviews_cont_item2 {
    font-family: 'AbhayaLibre'  !important;
    position: absolute;
    top: 30%;
    right: 25%;
    transform: translate(0%,30%);
    z-index: 10;
    padding: 32px;
    max-width: 454px;
    width: 100%;
    border-radius: 10px;
}

.rating_reviews_cont_item3 {
    position: absolute;
    top: -20%;
    right: 0%;
    transform: rotate(35deg);
    padding: 32px;
    max-width: 454px;
    width: 100%;
    border-radius: 10px;
}
.rating_reviews_cont_item_dates{
    margin: 40px auto;
    width: 100%;
}
.rating_reviews_cont_items_stars_block img{
margin-right: 4px;
}
.rating_reviews_cont_items_stars_block img:last-child{
    margin-right: 0px;
}
