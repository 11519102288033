.verify_email_cont{
    max-width: 613px;
    width: 100%;
    padding: 64px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -30%);
    margin: 16px;
}
.resend_mail{
    margin-top: 64px;
    color: midnightblue;
    cursor: pointer;
}
.resend_mail:hover{
  font-weight: 700;
}