.evriwork_payment_cont {
    margin-bottom: 234px;
}

.evriwork_payment_cont_block_text_b {
    margin-left: 70px;
}

.evriwork_payment_cont_block1 img {
    max-width: 1100px;
    width: 100%;
}

.evriwork_payment_cont_block1 .learn_more_btn {
    margin-top: 24px;
    padding: 12.5px;
    max-width: 287px;
    width: 100%;
    border-radius: 10px;
}
.evriwork_payment_cont_block1 .learn_more_btn:hover {
   transform: scale(1.03);
    font-weight: 700;
}

.evriwork_payment_cont_block2_item {
    max-width: 160px;
    width: 100%;
}
.evriwork_payment_cont_block2_item:hover{
   transform: scale(1.03);
}
.evriwork_payment_cont_block2_item img{
    max-width: 100px;
    width: 100%;
}