.dashboard_b2{
    margin-top: 136px;
}
.dashboard_b2_b{
    grid-gap: 62px;
    margin-top: 68px;
}

.dashboard_b2_b_item{
    max-width: 300px;
    padding: 37px 44px;
    width: 100%;
    height: 300px;

}
.dashboard_b2_b_item p{
    margin-top: 64px;
}