.about_us_cont{
    margin-top: 130px;
}
.about_container_b1{
    margin-top: 18%;
}

.about_container_b3{
    margin-top: 218px;
}
.about_container_b1_img_b{
    position: relative;
    max-width: 856px;
    width: 100%;

}
.about_container_b1_text_b{
    margin-left: 60px;
    max-width: 884px;
    width: 100%;
}
.about_container_b2_text_b{
    margin-right: 60px;
    max-width: 884px;
    width: 100%;
}
.about_pic_fon{
    position: absolute;
    top: -17%;
    left: 26%;
    z-index: -1;
    max-width: 630px;
    width: 100%;
}
.about_pic{
    max-width: 776px;
    width: 100%;
}
.about_container_b1_text_b h1{
    margin-bottom: 32px;
}
.about_container_b2{
    margin-top: 180px;
}