.explore_services_cont_items_block{
    margin-top: 100px;
}
.explore_services_cont_item {
    max-width: 584px;
    width: 100%;
    padding: 32px 16px;
    background: white;
    border-radius: 20px;
}
.explore_services_cont_item:hover {
  border: 2px solid #B9B9FF;
}

.explore_services_pic {
    max-width: 551px;
    width: 100%;
    margin-bottom: 24px;
}

.explore_services_cont_item h2 {
    margin-bottom: 16px;
}

.explore_services_cont_item .stars_block {
    margin-top: 28px;
}

.explore_services_cont_item .stars_block img {
    margin-left: 4px;
}
.explore_more_btn_block .explore_more_btn{
    width: 273px;
    height: 52px;
    border-radius: 10px;
}
.explore_more_btn_block .explore_more_btn:hover{
  transform: scale(1.03);
    font-weight: 700;
}