.post_task_cont{
    margin-bottom: -4px;
}
.postTask_cont_b1_text_block {
    position: absolute;
    top: 20%;
    left: 20%;
    max-width: 962px;
    width: 100%;
}
.post_task_cont p{

}
.post_task_cont button{
   max-width: 473px;
    width: 100%;
    height: 56px;
    border-radius: 10px;
}
.post_task_cont button:hover{
    transform: scale(1.02);
    font-weight: 700;
}

