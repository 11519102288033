.feel_secure_cont{

}
.feel_secure_cont_block{
    background: white;
    max-width: 584px;
    width: 100%;
    padding: 48px 40px 40px;
    text-transform: capitalize;
    border-radius: 12px;
    margin-left: 16px;
}
.feel_secure_cont_block:first-child{
    margin-left: 0px;
}
.feel_secure_cont_block:hover{
   transform: scale(1.03);
    border: 1px solid midnightblue;
}
.feel_secure_cont_blocks{
    margin-top: 48px;
}
.feel_secure_cont_block_img{
    max-width: 100px;
    width: 100%;
    margin-bottom: 24px;
}
.feel_secure_cont_block_img h2{
    margin-bottom: 8px;
}
.learn_more_btn{
    margin-top: 56px;
    cursor: pointer;
}
.learn_more_btn:hover{
   font-size: 18px;
    font-weight: 700;
}
.learn_more_btn img{
   margin-left: 14px;
}