.vButton {
    height: 44px;
    border-radius: 8px;
    border: 2px solid midnightblue;
    font-size: 16px;
    line-height:24px;
    font-weight: 500;
    color: white;
}
.vButton:hover{

}
