.verify_cont{
    max-width: 372px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}
.code_block{
    width: 100%;
    max-width: 312px;
    margin-right: auto;
    margin-left: auto;
}

.code_block  :where(.vi__character) {
    flex-grow: 1;
    flex-basis: 0;
    text-align: center;
    font-size: 25px !important;
    line-height: 50px;
    gap: 24px;
    color: black;
    cursor: default;
    user-select: none;
    box-sizing: border-box;
    width: 60px !important;
    height: 60px !important;
    border: 2px solid #191970;
    border-radius: 50% !important;
}

:where(.vi__container) {
    display: flex;
    gap: 24px !important;
}
:where(.vi__character--inactive) {
    color: dimgray;
    background: #F0F0F0 !important;
}
:where(.vi__wrapper) {
    position: relative;
    width: 100% !important;
}
.code_block input:last-child{
    margin-right: 0px;
}
.timer_block{
    margin-top: 32px;
}

.verify_btn_block{
    max-width: 285px !important;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.verify_btn_block_btn {
    padding: 19px;
    width: 100%;
    border-radius: 10px;
}

.verify_btn_block_btn:hover {
    transform: scale(1.03);
    font-weight: 700;
}
.receive_code_b{
    margin-top: 32px;
}
.receive_code_b span{
   color: #D69B04;
    cursor: pointer;
    margin-left: 5px;
}
.verify_cont_gen{
    max-width: 800px;
    width: 100%;
    padding: 62px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -30%);
    margin: 16px;
}

