.register_details_cont {
    padding: 32px;
    max-width: 800px;
    width: 100%;
    /*position: absolute;*/
    /*top: 50%;*/
    /*left: 50%;*/
    /*transform: translate(-50%, -30%);*/
    margin: 76px auto 160px;
}

.register_details_cont_block {
    margin-bottom: 24px;
    position: relative;
    /*max-width: 680px;*/
    width: 100%;
}

.register_details_cont_block label {
    margin-bottom: 8px;
    color: #646464;
}

.register_details_cont_block input, .register_details_cont_block .select_block {
    border: 1px solid #B4B4B4;
    border-radius: 8px;
    width: 100%;
    padding: 16px;
}

.register_details_cont_block textarea {
    border: 1px solid #B4B4B4;
    border-radius: 8px;
    width: 100%;
    padding: 16px;
    font-size: 16px;
    color: #8C8C8C;
}

.dropdown {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.dropdown_container {
    position: absolute;
    top: 48px;
    right: 0px;
    width: 100%;
    z-index: 999;
    background: white;
    border: 1px solid #B4B4B4;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    height: 159px;
    overflow-y: auto;
}

.dropdown_inner {
    padding: 16px;
}
.dropDawn_arrow_top{
    transform: rotate(180deg);
}
.dropdown_item{
    padding: 0px 0px 16px;
}
.dropdown_item:last-child{
    padding-bottom: 0px;
}
