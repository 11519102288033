.HowWorkBlock3_cont {
    margin-top: -10px;
}

.HowWorkBlock3_cont h2 {
    margin-bottom: 26px;
}

.HowWorkBlock3_cont_blocks {
    grid-row-gap: 32px;
    grid-column-gap: 24px;
}

.HowWorkBlock3_cont_blocks img {
    max-width: 584px;
    width: 100%;
}
.HowWorkBlock3_cont_blocks img:hover {
  transform: scale(1.03);
    border: 1px solid #49D69C;
    border-radius: 12px;
}