.clients_love_cont_blocks {
    position: relative;
}

.clients_love_cont_block1 {
    position: relative;
    background: #B9B9FF;
    max-width: 1400px;
    width: 100%;
    height: 727px;
    border-radius: 50px;
    box-shadow: 0px -16px 64px rgba(38, 38, 38, 0.08);
    margin: 66px auto;
}

.clients_love_cont_block2 {
    position: absolute;
    top: 15%;
}

.clients_love_cont_block2_item {
    background: white;
    box-shadow: 0px 0px 32px rgba(38, 38, 38, 0.08);
    border-radius: 10px;
    padding: 90px 32px;
}
.clients_love_cont_block2_item:hover {
   border: 2px solid midnightblue;
    background: #eaeafc;
}

.client_pic {
    max-width: 120px;
    width: 100%;
    margin-bottom: 24px;
}
.clients_love_cont_block2_item span{
    margin: 8px auto;
}
.clients_love_cont_block2_item .stars_block{
    margin-top: 24px;
}
.clients_love_cont_block2_item .stars_block img{
   width: 18px;
    margin-right: 5px;
}