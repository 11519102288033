.reg_prov_step_circle{
    background: #D9D9D9;
    width: 96px;
    height: 96px;
    border-radius: 50%;
}
.reg_prov_steps_cont{
    margin: 250px auto 0px;
    max-width: 1536px;
    width: 100%;
}
.reg_prov_step_title{
    margin-top: 16px;
    color: #A0A0A0;
}
.reg_prov_step_line{
    max-width: 500px;
    width: 100%;
    height: 8px;
    background: #D9D9D9;
    border-radius: 4px;
}
.activeStep_reg_prov_step_circle{
    width: 96px;
    height: 96px;
    border-radius: 50%;
}
.activeStep_reg_prov_step_title{
    margin-top: 16px;
    color: midnightblue;
}

