.table_cont{
    background: white;
    margin-top: 24px;
    padding: 24px;
}
.table{
    width: 100%;
}
.table tr th{
    padding: 16px;
}
.table tr td{
    padding: 16px;
}