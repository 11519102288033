.contact_us_cont {
    margin-top: 226px;
}

.contact_us_desc {
    margin: 24px auto 96px;
    max-width: 567px;
    width: 100%;
}

.contact_us_info {
    max-width: 966px;
    width: 100%;
    margin: auto;
}

.contact_us_info_block {
    border: 1px solid #C8C8C8;
    border-radius: 12px;
    padding: 24px;
    max-width: 292px;
    width: 100%;
    margin-right: 16px;
}

.contact_us_info_block:last-child {
    margin-right: 0px;
}

.contact_us_info_block:hover {
    border: 1px solid #000034;
}

.contact_us_info_block img {
    margin-bottom: 24px;
}

.contact_us_info_block p {
    white-space: nowrap;
    color: #3C3C3C;
}

.contact_us_block {
    padding: 48px 48px 32px;
    max-width: 790px;
    width: 100%;
    margin: 48px auto 120px;
}
.contact_us_block1_1{
    max-width: 331px;
    width: 100%;
}

.contact_us_block2 button {
    max-width: 273px;
    width: 100%;
    border-radius: 10px;
    padding: 19px;
}

.contact_us_block2 button:hover {
    transform: scale(1.03);
    font-weight: 700;
}

.contact_us_block1_1_block {
    position: relative;
    margin-bottom: 24px;
}

.contact_us_block1_1_block input {
    margin-top: 8px;
    max-width: 331px;
    width: 100%;
    padding: 19px 19px 19px 48px;
    border: 1px solid #C8C8C8;
    border-radius: 8px;
    color: #282c34;
    font-size: 16px;
}

.contact_us_block1_1_block img {
    position: absolute;
    top: 50%;
    left: 15px;
}

.contact_us_block1_2 {
    /*margin-left: 32px;*/
}

.contact_us_block1_2 textarea {
    margin-top: 8px;
    width: 331px;
    height: 200px;
    border: 1px solid #C8C8C8;
    border-radius: 8px;
    padding: 8px 12px;
    color: #282c34;
    font-size: 16px;
}