.total_earned_b{
    margin-left: 16px;
}
.total_earned_b label{
    margin-bottom: 16px;
}
.payment_history_b1_2{
    width: 100%;
    max-width: 473px;
    margin: 48px auto 61px;
}
.payment_history_b1_2 img{
    width: 100%;
}

.payment_history_b1_2_btns{
    margin-top: 74px;
}
.payment_history_b1_2_btns .post_task_btn{
   padding: 24px 12px;
    background: midnightblue;
    margin-top: 0px;
    line-height: 24px !important;
    height: unset !important;
}
.payment_history_b1_2_btns .post_task_btn:hover{
   transform: scale(1.03);
    font-size: 24px;
}
.payment_history_b1_2_btns .view_task_btn{
    padding: 24px 34px;
    background: #49D69C;
    margin-top: 0px;
    border: none !important;
    line-height: 24px !important;
    height: unset !important;
    white-space: nowrap;
    width: unset !important;
}
.payment_history_b1_2_btns .view_task_btn:hover{
   transform: scale(1.03);
}