.dashboard_b1_1{
    width: 100%;
}
.dash_post_btn{
    background: #191970;
    border-radius: 10px;
    width: 162px;
    height: 48px;
}
.dash_post_btn:hover{
   transform: scale(1.03);
    font-weight: 700;
}
.view_task_btn{
    margin-left: 20px;
    width: 162px;
    height: 48px;
    border: 1px solid #D69B04;
    border-radius: 10px;
}
.view_task_btn:hover{
   background: midnightblue;
    transform: scale(1.03);
    font-weight: 700;
}

.dashboard_b1_2{
    width: 100%;
    max-width: 620px;
    margin: 48px auto 61px;
}
.dashboard_b1_2 img{
    width: 100%;
    margin-bottom: 64px;

}