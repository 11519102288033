.get_work_cont{
}
.get_work_cont_items{

}
.get_work_cont_item{
    max-width: 398px;
    width: 100%;
}
.get_work_cont_item:hover{
   transform: scale(1.02);
    color: midnightblue;
}
.get_work_cont_item img{
    max-width: 120px;
    width: 100%;
    margin-bottom:16px;
}
.get_work_cont_item h2{
    margin-bottom:16px;
}