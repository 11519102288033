.header {
    background: white;
    position: fixed;
    top: 0;
    width: 100%;
    /*height: 130px;*/
    z-index: 99;
}
.header_dashboard{
    background: white;
    width: 100%;
}
.header_dashboard_container{
    background: white;
    max-width: 1429px;
    margin: auto;
    width: 100%;
    z-index: 99;
}
.header_dashboard.active.header_cont{
   display: none;
}
.header_cont {
    padding: 43px 0px;
}
.header_dashboard_cont {
    padding: 28px 0px;
}

.header_navlinks_cont {
    margin-left: 24px;
}

.logo {
    cursor: pointer;
}

.header_navLink, .header_navLink_active {
    color: #787878;
    padding: 0px 7px;
    margin-right: 24px;
}

.header_navLink:last-child {
    margin-right: 0px;
}

.header_navLink:hover {
    transform: scale(1.02);
    font-weight: 700;
    padding-bottom: 5px;
    border-bottom: 5px solid #191970;

}

.header_navLink_active {
    padding-bottom: 5px;
    color: #191970;
    font-weight: 700;
    border-bottom: 5px solid #191970;
}

.login_register_btn_block {
    margin-left: 60px;
}

.sign_in_btn {
    width: 110px;
    height: 44px;
    border: 1px solid #191970;
    border-radius: 8px;
    margin-right: 16px;
}

.sign_in_btn:hover {
    background: #191970;
    color: white;
    transform: scale(1.05);
}

.register_btn {
    width: 132px;
    height: 44px;
    border-radius: 8px;
}

.register_btn:hover {
    background: transparent;
    color: midnightblue;
    border: 1px solid #191970;
}

.notification_icon_b {
    margin-right: 24px;
}

.user_pic_b img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.dashboard_header_b1{
    /*max-width: 891px;*/
    /*width: 100%;*/
}
.header_search_input_b{
    max-width: 420px;
    width: 100%;
    position: relative;
}
.header_search_input_b input{
    width: 100%;
    background: #F0F0F0;
    border-radius: 4px;
    padding: 11px 11px 11px 44px;
}
.header_search_input_b input::placeholder{
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 1.25px;
    color: #B4B4B4;
}
.header_search_input_b:before{
  content: url("../../assets/images/search_icon.svg");
    position: absolute;
    top: 12px;
    left: 11px;
}
.notification_block{
    margin-left: 24px;
}