.services_block1{
    margin-top: 130px;
    margin-bottom: 160px;
}
.services_block1_1{
    max-width: 884px;
    width: 100%;
}
.services_block1_1 h2{
   margin-bottom: 32px;
}
.services_block1_2{
   max-width: 582px;
    width: 100%;
    margin-left: 20px;
}
.services_block1_2 img{
    width: 100%;
}
