.HowWorkBlock1_cont {
    margin-top: 130px;
    padding: 160px 0 66.5px;
}

.HowWorkBlock1_cont .how_works_btn {
    border-radius: 10px;
    max-width: 315px;
    width: 100%;
    padding: 14px 83px;
}
.HowWorkBlock1_cont .how_works_btn:hover {
  transform: scale(1.03);
    font-weight: 700;
}
.HowWorkBlock1_cont_block2{
    max-width: 1331px;
    width: 100%;
    margin: 136px auto 0px;
}
.HowWorkBlock1_cont_block2 p{
   margin-right: 10px;
}
.HowWorkBlock1_cont_block2 p:last-child{
    margin-right: 0px;
}
.HowWorkBlock1_cont_block1{
    grid-row-gap: 125px;

}
.HowWorkBlock1_cont_block1_block{
    padding: 32px;
    border-radius: 12px;
}
.HowWorkBlock1_cont_block1_block:hover{
    transform: scale(1.02);
}
.HowWorkBlock1_cont_block1_block img{
   max-width: 336px;
    width: 100%;
}