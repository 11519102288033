.home_cont_b1 {
    position: relative;
    margin-top: 130px;
}

.home_cont_b1 img {

}

.home_general_pic {
    width: 100%;
}

.home_general_pic_fon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.chat_icon {
    max-width: 100px;
    width: 100%;
    position: absolute;
    right: 5%;
    bottom: 1%;
}

.home_cont_b1_text_block {
    position: absolute;
    top: 20%;
    left: 20%;
    max-width: 962px;
    width: 100%;
}

.home_cont_b1_text_block h1 {
    margin-bottom: 21px;
}

.post_task_btn {
    margin-top: 64px;
    max-width: 292px;
    width: 100%;
    height: 44px;
    border-radius: 8px;
}

.post_task_btn:hover {
    background: #000034;
    font-size: 18px;
}
