.btn_file {
    position: relative;
    overflow: hidden;
}

.btn_file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

.drag_drop_cont {
    position: relative;
    border-bottom: 1px dashed grey;
}

.sc-aXZVg{
    border-top: none !important;
    border-left: 1px dashed midnightblue !important;
    border-right: 1px dashed midnightblue !important;
    border-bottom: 1px dashed midnightblue !important;
    height: 180px !important;
    display: flex !important;
    flex-direction: column !important;
}
.sc-eqUAAy{
    display: none !important;
}
.sc-aXZVg svg {
    display: none !important;
}

.sc-aXZVg:after {
    content: url("../../../../../../assets/images/folder-add.png") !important;
    position: absolute !important;
    top: 20% !important;
}

.sc-aXZVg:after {
    content: url("../../../../../../assets/images/folder-add.png") !important;
    position: absolute !important;
    top: 25% !important;
}

.upload_b{
    position: absolute;
    top: 50%;
    left: 30%;
}

.drag_drop_btn{
   margin-top: 32px;
    border: 1px solid white;
}
