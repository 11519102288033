.identification_cont h2{
    margin-bottom: 16px;
}
.valid_pic_block{
margin-bottom: 30px;
}
.valid_pic_block img{
    width: 100%;
    max-width: 289px;
}
.upload_img_btn{
    width: 417px;
    padding: 16px 12px;
    border-radius: 8px;
    background: #0D9A60;
}
.upload_img_btn:hover{
 transform: scale(1.03);
 font-weight: 700;
}
.valid_content_block{
    max-width: 417px;
    width: 100%;
    margin: auto;
}
.btn-file {
    position: relative;
    overflow: hidden;
}
.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}