.footer_cont{
    padding: 32px 0px 108px;
}
.footer_cont_block1{
    max-width: 397px;
    width: 100%;
}
.footer_cont_block1 div{
    margin-top: 16px;
}
.social_blocks{
    margin-top: 32px !important;
}
.social_blocks a{
    margin-right: 24px;
}
.footer_logo{
    max-width: 200px;
    width: 100%;
}
.footer_cont_block2{
    max-width: 1200px;
    width: 100%;
}
.footer_cont_block2_item{
    margin-right: 20px;
}
.footer_cont_block2_item h3{
    color: #DCDCDC;
}

.footer_navLink, .footer_navLink_active{
    margin-top: 16px;
}

.footer_navLink:hover, .footer_navLink_active:hover{
    color: white;
}
.contact_block a{
    margin-top: 16px;
}
.contact_block img{
    margin-right: 13px;
    max-width: 26px;
}
