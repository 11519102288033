.login_register_cont {
    max-width: 800px;
    width: 100%;
    padding: 62px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -30%);
    margin: 16px;
}

.login_register_cont_blocks {
    margin-top: 70px;
}

.login_register_cont_block {
    margin-bottom: 24px;
    position: relative;
    max-width: 680px;
    width: 100%;
}

.login_register_cont_block label {
    margin-bottom: 8px;
    color: #8C8C8C;
}

.login_register_cont_block input {
    background: #F0F0F0;
    border-radius: 8px;
    width: 100%;
    padding: 16px 46px 16px 48px;
}

.login_register_cont_block img {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 55%;
    left: 16px;
}

.login_register_cont_sign_btn {
    padding: 19px;
    width: 100%;
    border-radius: 10px;
}

.login_register_cont_sign_btn:hover {
    transform: scale(1.03);
    font-weight: 700;
}

.remember_me_block input {
    width: 24px;
    height: 24px;
    background: #E0E0E0 !important;
    border-radius: 4px;
}

.remember_me_block label {
    margin-left: 16px;
    color: #8C8C8C;
}

/*input[type="checkbox"] {*/
/*    accent-color: #191970;*/
/*}*/
input[type=checkbox] {
    position: relative;
    cursor: pointer;
}

input[type=checkbox]:before {
    content: "";
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    top: 0;
    left: 0;
    border-radius: 3px;
    background-color: #E0E0E0;
}

input[type=checkbox]:checked:after {
    content: "";
    display: block;
    width: 5px;
    height: 10px;
    border: solid midnightblue;
    border-width: 0px 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 3.5px;
    left: 9px;
}

.remember_forget_pass_block {
    margin-top: -5px;
}

.remember_forget_pass_block .forget_pass {
    color: #3636FC;
    margin-left: 16px;
    cursor: pointer;
}

.sign_in_using {
    margin: 32px auto;
}

.login_social_blocks div:first-child {
    margin-right: 27px;
}

.login_social_blocks a:hover {
    transform: scale(1.03);
}

.create_account_block p {
    margin-right: 5px;
}

.create_account_block div:hover {
    font-weight: 700;
    cursor: pointer;
}

.hide_icon, .show_icon {
    position: absolute;
    top: 55%;
    right: 2%;
    width: 28px;
    height: 25px;
    color: #A0A0A0;
}
