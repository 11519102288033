.superHeroes_cont{
    width: 100%;
    padding: 138px 20px 192px;
}
.superHeroes_cont_blocks{
    margin: 124px auto 0;
    grid-column-gap: 96px;
    grid-row-gap: 148px;
    max-width: 1512px;
    width: 100%;
}
.superHeroes_cont_block img{
    max-width: 440px;
    width: 100%;
}
.superHeroes_cont_block h3{
  margin:32px auto 16px;
}