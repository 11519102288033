.loading_block{
    width: 100%;
    /*height: 100vh;*/
}
.loading_block div{
    max-width: 200px !important;
    width: 100% !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.loading_block div svg{
    max-width: 200px !important;
    width: 100% !important;
  fill: #3dc990 !important;
/*fill:conic-gradient(from 180deg at 50% 50%, #49D69C 0deg, rgba(73, 214, 156, 0) 360deg);*/
}
.ReactModal__Overlay.ReactModal__Overlay--after-open{
    z-index: 999 !important;
}
