.remember_me_block label{
    color: black !important;
}
.remember_me_block label div{
    margin: 0px 5px;
    color: #3636FC;
    cursor: pointer;
}
.remember_me_block label div:hover{
   font-weight: 700
}
.verify_email_block{
    max-width: 800px;
    width: 100%;
    padding: 62px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -30%);
    margin: 16px;
}