.select_cont{
    border: 0.5px solid #B4B4B4;
    border-radius: 4px;
    height: 44px;
    padding: 13px;
    width: 100%;
    color: #646464;
    background: transparent;
}
.select_item_cont {
    width: 100%;
    border: 1px solid #E6EAF1;
    z-index: 999;
    position: relative;
    background: white;
    max-height: 150px;
    overflow-y: auto;
}
.select_item {
    width: 100%;
    padding: 12px 15px;
    cursor: pointer;
}

.select_item:hover {
    background: rgb(204, 234, 239);
}